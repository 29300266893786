.about__img {
  // margin-top: 8rem;
  width: 100vw;
  height: 70vw;
  position: fixed;
  top: 8rem;
  background-image: url(./images/bitmoji.png);
  background-position: center;
  
}

.about {
  margin-top: 70vh;
  background-color: #000;
  box-shadow: 0px -20rem 30rem rgba(0, 0, 0, 0.404);
}

.billboard__about {
  h1 {
    -webkit-text-stroke: 1px rgb(244, 239, 236);
    color: transparent;
    text-align: right;
    font-size: clamp(6rem, 8.5vw, 9.5rem) !important;
  }

  h2 {
      font-size: clamp(3rem, 3.5vw, 4.2rem);
      font-weight: 600;
      line-height: 1.9;
      text-align: left;
      text-transform: uppercase;
      color: rgb(244, 239, 236);
  }

  p {
    margin: 10rem 0;
      font-size: clamp(2.5rem, 3vw, 4rem);
      font-weight: 400;
      line-height: 1.5;
      text-align: left;
      color: rgb(244, 239, 236);
  }

}

.billboard__resume {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10rem;

  a {
    padding: 2rem 4rem;
  border-radius: 8px;
  border: 2px solid #FB5827;
  color: #FB5827;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Avenir Next', sans-serif;
  text-align: left;
  cursor: pointer;


  background: transparent;
  background-image: radial-gradient(
    rgba(0, 0, 0, 0) 1px,
    #FB5827 7px
  );
  background-size: 4px 4px;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  -moz-backdrop-filter: blur(2px);
  }
  
}

.tools {
  margin: 20rem 0;

  &__images {
    display: flex;
    justify-content: space-between;
  
    img {
      width: 7rem;
      height: 7rem;
    }
  }
}

.billboard__quote {

  h1 {
    color: rgb(244, 239, 236);
  }

  span {
    -webkit-text-stroke: 1px #FB5827;
    color: transparent;
  }
}


@media (max-width: 730px) {
  .about {
    margin-top: 40vh;
  }

  .billboard__resume {
    margin-bottom: 5rem;
  }

  .tools {
    margin: 10rem 0;
  
    &__images {
      img {
        width: 5rem;
        height: 5rem;
      }
    }
  }
}

@media (max-width: 375px) {
  .billboard__resume {
    margin-bottom: 5rem;
  }

  .tools {
    margin: 7rem 0;
  
    &__images {
      img {
        width: 4rem;
        height: 4rem;
      }
    }
  }

  .billboard__quote{
    margin-top: 0 !important;
  }
}