.project__page {
    color: #fff;
    padding-top: 4rem;
    padding-bottom: 15rem;

    &-title {
      height: 30vw;
    //   display: flex;
      align-items: end;
      background-color: #131517;

      h2 {
        padding-top: 10rem;
        font-size: clamp(3.5rem, 4vw, 6.2rem);
        font-weight: 600 !important;
        -webkit-text-stroke: 1px rgb(244, 239, 236);
        color: transparent;
        padding-right: 20%;
      }

    }

    &-text {
      font-size: clamp(2.5rem, 2vw, 2.5rem);

      &-bold {
        font-weight: 600;
      }

      ul {
        margin-left: 40px;
      }

      li {
       padding-bottom: 15px;
      }

      &-italic {
        font-style: italic;
      }
    }


    &-heading {
      font-size: clamp(2.5rem, 2vw, 3rem);
      font-weight: 600;
      padding-top: 30px;
      padding-bottom: 10px;
      color: #7c4dffb3;
    }

    &-heading-primary {
      font-size: clamp(2.5rem, 2vw, 3rem);
      font-weight: 600;
      padding-bottom: 10px;
      color: #7c4dffb3;
    }

    &-tools {
      padding-bottom: 4rem;

      p {
        font-size: 1.8rem;
        font-weight: 600;
        color: #E64A19;
      }

      span {
        padding-left: 1rem;
        color: rgb(244, 239, 236);
        font-weight: 500;
      }
      
    }

    &-btns {
        display: flex;
        gap: 10rem;
        justify-content: center;
        align-items: center;
        padding-top: 10rem;

        a {
            display: flex;
            font-size: clamp(1.8rem, 2vw, 3rem);
            font-weight: 600 !important;
            cursor: pointer;
            text-transform: uppercase;
            border-bottom: 2px solid rgb(244, 239, 236);
            transition: all .2s;

            &:hover {
                background: -webkit-linear-gradient(#7C4DFF, #29B6F6, #E64A19);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                border-bottom: 2px solid #29B6F6;
            }
            
        }

        p {
            padding-right: 1rem;
        }

        svg {
            width: clamp(1.8rem, 2vw, 3rem);
            height: clamp(1.8rem, 2vw, 3rem);
            margin-top: .5rem;
        }
    }
}

@media (max-width: 730px) {
  .project__page {

    &-title {
      height: 40vw;

      h2 {
        padding-right: 5%;
      }
    }

    &-tools {padding-bottom: 4rem;

      p {
        font-size: 1.5rem;
      }

      span {
        padding-left: .5rem;
      }
      
    }

    &-btns {
      gap: 5rem;
      align-items: flex-end;

      a {
        flex-basis: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

@media (max-width: 493px) {
  .project__page {

    &-title {
      height: 60vw;

      h2 {
        padding-right: 5%;
      }
    }
  }
}



.improdds__screenshot-top {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 75%;
  margin: 50px auto;
  background-color: #131517;
  padding: 50px;

  &-image {
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.improdds__screenshot-label {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5px;

  &::marker {
    margin: 0 !important;
  }
}


.improdds__screenshot-bottom {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin: 50px auto;
  background-color: #131517;
  padding: 50px;

  &-box {
    display: flex;
    gap: 10px;
  }

  &-image {
    // width: 30%;

    img {
      width: 100%;
      height: auto;
    }
  }

  &-image-box {
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 700px) {
  .improdds__screenshot-top {
    gap: 5px;
    width: 100%;
    margin: 50px auto;
    background-color: #131517;
    padding: 10px;
  
    &-image {
      width: 100%;
  
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .improdds__screenshot-bottom {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    margin: 50px auto;
    background-color: #131517;
    padding: 10px;

    &-box {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}

.project-page-sub-paragraph {
  padding-bottom: 10px;
}